import {
  Box,
  Button,
  Center,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import logo from "./../Assets/logo2.png";
import { FaBook, FaHome } from "react-icons/fa";
import { FcAbout } from "react-icons/fc";
import { MdContactPhone, MdOutlineDashboard } from "react-icons/md";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("userInfo") !== null);
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("userInfo");
    setIsLoggedIn(false);
    navigate("/");
    window.location.reload();
  };

  const userdashbord = () => {
    navigate("/user-dashboard");
  };

  const loginhandle = () => {
    navigate("/login");
  };

  const registerhandle = () => {
    navigate("/register");
  };

  return (
    <nav style={{ background: "floralwhite" }}>
      <Box
        display="flex"
        alignItems="center"
        padding={4}
        justifyContent="space-between"
      >
        <Link to="/">
          <img src={logo} alt="Logo" style={{ width: "80px" }} />
        </Link>
        {isMobile ? (
          <Box display="flex" alignItems="center">
            {isLoggedIn ? (
              <>
                <Button
                  marginRight={2}
                  colorScheme="blue"
                  onClick={logoutHandler}
                >
                  Logout
                </Button>
                <Button
                  marginRight={2}
                  colorScheme="green"
                  onClick={userdashbord}
                >
                  <MdOutlineDashboard />
                </Button>
              </>
            ) : (
              <>
                <Button
                  marginRight={2}
                  colorScheme="blue"
                  onClick={loginhandle}
                >
                  Login
                </Button>
                <Button
                  marginRight={2}
                  colorScheme="blue"
                  onClick={registerhandle}
                >
                  Register
                </Button>
              </>
            )}
            <PiDotsThreeCircleLight
              onClick={onToggle}
              style={{
                height: "50px",
                width: "50px",
                color: "black",
                cursor: "pointer",
              }}
            />
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <Box display="flex" gap={4}>
              <Link to="/">
                <Button
                  marginRight={2}
                  colorScheme=""
                  color="black"
                  _hover={{ backgroundColor: "red", color: "white" }}
                >
                  <FaHome style={{ marginRight: "5px" }} /> Home
                </Button>
              </Link>
              <Link to="/courses">
                <Button
                  marginRight={2}
                  colorScheme=""
                  color="black"
                  _hover={{ backgroundColor: "red", color: "white" }}
                >
                  <FaBook style={{ marginRight: "5px" }} /> Course
                </Button>
              </Link>
              {/* <Link to="/test-seris">
                <Button
                  marginRight={2}
                  colorScheme=""
                  color="black"
                  _hover={{ backgroundColor: "red", color: "white" }}
                >
                  <FaBook style={{ marginRight: "5px" }} /> Test Series
                </Button>
              </Link> */}
              <Link to="/about-us">
                <Button
                  marginRight={2}
                  colorScheme=""
                  color="black"
                  _hover={{ backgroundColor: "red", color: "white" }}
                >
                  <FcAbout style={{ marginRight: "5px" }} /> About
                </Button>
              </Link>
              <Link to="/contact-us">
                <Button
                  marginRight={2}
                  colorScheme=""
                  color="black"
                  _hover={{ backgroundColor: "red", color: "white" }}
                >
                  <MdContactPhone style={{ marginRight: "5px" }} /> Contact
                </Button>
              </Link>
            </Box>
            <Box display="flex" alignItems="center">
              {isLoggedIn ? (
                <>
                  <Button
                    marginRight={2}
                    colorScheme="blue"
                    onClick={logoutHandler}
                  >
                    Logout
                  </Button>
                  <Button
                    marginRight={2}
                    colorScheme="green"
                    onClick={userdashbord}
                  >
                    {/* <MdOutlineDashboard /> */}
                    Dashboard
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    marginRight={2}
                    colorScheme="blue"
                    onClick={loginhandle}
                  >
                    Login
                  </Button>
                  <Button
                    marginRight={2}
                    colorScheme="blue"
                    onClick={registerhandle}
                  >
                    Register
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
      {isOpen && (
        <Box
          display={{ base: "block", md: "none" }}
          position="absolute"
          top="60px"
          right="0"
          bg="white"
          width="100%"
          p={4}
          boxShadow="md"
        >
          <Link to="/">
            <Button marginBottom={2} width="100%" onClick={onClose}>
              Home
            </Button>
          </Link>
          <Link to="/courses">
            <Button marginBottom={2} width="100%" onClick={onClose}>
              Course
            </Button>
          </Link>
          {/* <Link to="/test-seris">
            <Button marginBottom={2} width="100%" onClick={onClose}>
              Test Series
            </Button>
          </Link> */}
          <Link to="/about-us">
            <Button marginBottom={2} width="100%" onClick={onClose}>
              About
            </Button>
          </Link>
          <Link to="/contact-us">
            <Button marginBottom={2} width="100%" onClick={onClose}>
              Contact
            </Button>
          </Link>
          {isLoggedIn ? (
            <>
              <Button
                marginBottom={2}
                width="100%"
                colorScheme="blue"
                onClick={logoutHandler}
              >
                Logout
              </Button>
              <Button
                marginBottom={2}
                width="100%"
                colorScheme="green"
                onClick={userdashbord}
              >
                Dashboard
              </Button>
            </>
          ) : (
            <>
              <Button
                marginBottom={2}
                width="100%"
                colorScheme="blue"
                onClick={loginhandle}
              >
                Login
              </Button>
              <Button
                marginBottom={2}
                width="100%"
                colorScheme="blue"
                onClick={registerhandle}
              >
                Register
              </Button>
            </>
          )}
        </Box>
      )}
    </nav>
  );
};

export default Navbar;
