import { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import Forget from "./Components/Auth/Forget";
import Homepage from "./Components/Layout/Homepage";
import Courses from "./Components/Layout/Courses";
import Testseries from "./Components/Layout/Testseries";
import Aboutus from "./Components/Pages/Aboutus";
import Conact from "./Components/Pages/Conact";
import Home from "./Components/Dashboard/Home";
import CoursesDEtails from "./Components/Layout/CoursesDEtails";
import Checkout from "./Components/Layout/Checkout";
import LearnCourse from "./Components/Dashboard/LearnCourse";
// import PopupForm from "./Components/Layout/PopupForm";

function App() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo?.token;

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* {userInfo ? (
            <> */}
          <Route path="/user-dashboard" element={<Home />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/get_course/:slug" element={<CoursesDEtails />} />
          <Route path="/learn_course/:slug" element={<LearnCourse />} />
          <Route path="/checkout/:_id" element={<Checkout />} />
          <Route path="/test-seris" element={<Testseries />} />
          <Route path="/about-us" element={<Aboutus />} />
          {/* <Route path="/tets" element={<PopupForm />} /> */}
          <Route path="/contact-us" element={<Conact />} />
          <Route path="*" element={<Navigate to="/" />} />
          {/* </>
          ) : (
            <> */}
          <Route path="/" element={<Homepage />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/get_course/:slug" element={<CoursesDEtails />} />
          <Route path="/checkout/:_id" element={<Checkout />} />
          <Route path="/test-seris" element={<Testseries />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/contact-us" element={<Conact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="*" element={<Navigate to="/" />} />
          {/* </>
          )} */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
